@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }

  #about,
  /* #services,
  #testimonials,
  #team,
  #contact, */
  #links,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

.navbar img {
  height: auto;
  width: 200;
}